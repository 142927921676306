import React from "react"
import PropTypes from "prop-types"
import Cookies from 'js-cookie';
import logo from '../images/patagoniaBirthLogo.png'
import filled from '../images/birthRememberFilled.png'
import unfilled from '../images/birthRememberUnfilled.png'
import { Link } from "gatsby"
import divider from '../images/agegate/divider.svg'
import holaContainer from "../images/hola/holaContainer2.svg"
import { ReactSVG } from "react-svg"

function _calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default class BirthConsent extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      remember: false,
      day: '',
      month: '',
      year: ''
    }
  }

  componentDidMount() {

    var container = document.getElementsByClassName("birthOptionsWrapper")[0];
    container.onkeyup = function(e) {
      var target = e.srcElement || e.target;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var next = target;
        while (next = next.nextElementSibling) {
          if (next == null)
            break;
          if (next.tagName.toLowerCase() === "input") {
            next.focus();
            break;
          }
        }
      }
      // Move to previous field if empty (user pressed backspace)
      else if (myLength === 0) {
        var previous = target;
        while (previous = previous.previousElementSibling) {
          if (previous == null)
            break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
          }
        }
      }
    }
  }

  handleBirthConsent = () => {

    let birthday = new Date( );
    birthday.setDate(this.state.day)
    birthday.setMonth(parseFloat(this.state.month) - 1);
    birthday.setFullYear(this.state.year);
    console.log(_calculateAge(birthday))

    if (this.state.day && this.state.year && this.state.month ){
      if (_calculateAge(birthday) >=18){
        if (this.state.remember ){

          let d = new Date();
          d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
          Cookies.set('patagoniaBirthConsent', true, {expires: d});
        }
        localStorage.setItem('patagoniaBirthConsent', true);
        this.props.handleConsent();
      }
    }


  };

  handleRemember = () =>{
    this.setState({
      remember: !this.state.remember,
    })
  }

  handleChange = event =>{

    let name = event.target.name;
    let value = event.target.value

    if (name === 'day' || name === 'month'){
      let valueSlice = value.slice(0,2)
      this.setState({
        [name]: valueSlice
      })
    }
    else{
      let valueSlice = value.slice(0,4)
      this.setState({
        [name]: valueSlice
      })
    }

  };

  render() {

    return (
      <div className={'birthConsentWrapper'}>
        <img alt={'patagoniaBirthLogo'} className={'patagoniaBirthLogo'} src={logo}/>
        <p className={'birthQuestion'}>INGRESA TU FECHA DE NACIMIENTO<br/>PARA CONTINUAR</p>
        <div className={'birthOptionsWrapper'}>
         {/* <span onClick={this.handleBirthConsent} className={'birthSelected'}>SI</span>
          <span>NO</span>*/}
          <input maxlength="2" name={'day'} onChange={this.handleChange} value={this.state.day} placeholder={'DD'} type="number" pattern="\d*"/>
          <img className={'divider'} src={divider} alt=""/>
          <input maxlength="2" name={'month'} onChange={this.handleChange} value={this.state.month} placeholder={'MM'} type="number" pattern="\d*"/>
          <img className={'divider'} src={divider} alt=""/>
          <input maxlength="4" name={'year'} onChange={this.handleChange} value={this.state.year} placeholder={'AAAA'} type="number" pattern="\d*"/>
        </div>
        <button onClick={this.handleBirthConsent} className="enterButton">Entrar</button>
        <div className={'footerTermsWrapper footerBirth'}>
          <div className={'birthRemember'}>
            <img onClick={this.handleRemember} src={this.state.remember ? filled : unfilled} className={this.state.remember ? 'rememberImg rememberFilled': 'rememberImg'} alt=""/>
            <span onClick={this.handleRemember}>RECORDARME</span>
          </div>
          <div className={'footerSocialInfo'}>
            <div>
              <a href={'../../docs/TyC.docx'} className={'footerLink footerLinkText'} >Términos y condiciones</a>
              <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
              <a href={'../../docs/POL.docx'} className={'footerLink footerLinkText'} >Políticas de privacidad</a>
              <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
              <a href={'http://franquiciaspatagonia.com.ar/'} className={'footerLink footerLinkText'}>Franquicias</a>
              <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
              <Link className={'footerLink footerLinkText'} to={'https://www.tapintoyourbeer.com/agegate?destination=age_check.cfm'}>tapintoyourbeer</Link>
              <div className={'footerMessage'}>No se quede conectado si comparte el dispositivo con menores. Usamos cookies para mejorar nuestros servicios.
                Al continuar la navegación está aceptando los términos de uso, las políticas de privacidad y el uso de las cookies</div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}